.FilledButton {
  width: 192px;
  height: 72px;
  border: 8px solid #FFF;
  box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
  border-radius: 20px;
  cursor: pointer;
  font-weight: 900;
  font-size: 20px;
  text-align: center;
}
