.BottomAppBar {
  height: 118px;
  background: var(--theme-primary-container);
  box-shadow: 0 -4px 8px var(--color-shadow);
  z-index: 100;
  display: flex;
  justify-content: center;

  .leading {
    flex: 1;
  }

  .navigation {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;

    .item {
      width: 112px;
    }
  }

  .progress {
    flex: 1;
    color: var(--color-text-primary);
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 8px;
    padding-bottom: 4px;
  }
}
