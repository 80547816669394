.ToggleSwitch {
  position: absolute;
  width: 554px;
  height: 65px;
  left: 406px;
  top: 116px;
}

.switch_label {
  cursor: pointer;
}

.input {
  position: absolute;
  display: none;
}

.background {
  position: absolute;
  width: 554px;
  display: block;
  text-align: left;
  height: 65px;
  background-color: #C7C5C5;
  border-radius: 40px;
}

.switchText {
  position: absolute;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 51px;
  text-align: center;
  color: var(--color-white);
  top: 10.77%;
  bottom: 10.77%;
}

.textTraining {
  left: 15.7%;
  right: 64.62%;
}

.textQuiz {
  left: 58.14%;
  right: 9%;
}

.button {
  position: relative;
  height: 65px;
  width: 282px;
  border-radius: 40px;
  background: linear-gradient(180deg, #FFEA28 0%, #FF900E 86.98%);
  box-shadow: 0 4px 4px var(--color-shadow);
  transition: 0.4s;
}

.input:checked~.button {
  transition: 0.4s;
  transform: translateX(96.5%);
}