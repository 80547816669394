.CalendarFooter {
  background: transparent;
  width: 1262.88px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .penguin {
    margin-right: -46px;
    margin-bottom: -2px;
  }

  .balloon {
    position: relative;
    width: 558px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
    font-weight: 900;
    font-size: 30px;
    color: #1D367FE7;
  }

  .box {
    --color-text: rgb(150 149 149 / 100%);
    --color-term: rgb(74 74 74 / 100%);

    position: relative;
    width: 208px;
    height: 138px;
    background: var(--color-white);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 900;
    font-size: 50px;
    margin-right: 20px;
    margin-bottom: 7px;
    padding-bottom: 20px;

    .percent {
      position: absolute;
      top: 83px;
      left: 161px;
      font-size: 30px;
      color: var(--color-term);
    }

    .correctAnswerRateText {
      width: 189px;
      position: absolute;
      top: 7px;
      left: 11px;
      font-size: 30px;
      color: var(--color-text);
    }

    .date {
      position: absolute;
      top: 83px;
      left: 161px;
      font-size: 30px;
      color: var(--color-term);
    }

    .loginText {
      width: 189px;
      position: absolute;
      top: 7px;
      left: 11px;
      font-size: 30px;
      color: var(--color-text);
    }
  }
}
