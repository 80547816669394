.TopAppBar {
  background: var(--color-purple);
  box-shadow: 0 4px 4px var(--color-shadow);
  width: 1366;
  height: 75px;
  display: flex;
  align-items: center;

  .title {
    font-style: normal;
    font-size: 40px;
    font-weight: 900;
    line-height: 58px;
    color: var(--color-white);
    text-align: center;
    flex-grow: 1;
    padding: 0 16px;
  }
}