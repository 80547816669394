@use "../../styles/mixin";

.Quiz {
  @include mixin.page;

  background: var(--color-blue-lighten);

  --answering-primary: var(--color-green);
  --answering-primary-container: var(--color-green-lighten);
  --result-primary: var(--color-red);
  --result-primary-container: var(--color-red-lighten);
}
