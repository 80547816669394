.title {
  font-weight: bold;
  color: var(--color-text-primary);
  text-align: center;
  flex-grow: 1;
  padding: 0 16px;
}

.small-font {
  font-size: 20px;
}

.large-font {  
  font-size: 40px;
}