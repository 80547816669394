@use "../../styles/mixin";

@mixin unit-selection($background-color, $border-color) {
  @include mixin.page;

  background: $background-color;
  border-width: 28px 33px;
  border-style: solid;
  border-color: $border-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  .categoryList {
    margin: auto;
  }

  .unlockFukidashi {
    position: absolute;
    top: 0;
    left: 0;
    width: 668px;
    height: 103px;
    display: flex;
    padding: 40px;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("./assets/unlockFukidashi.svg");

    p {
      font-weight: 600;
      font-size: 30px;
      color: #864F1D;
    }
  }
}

.CategorySelection {
  &Science {
    @include unit-selection(#D5F8D2, #91DA8B);
  }

  &SocialStudies {
    @include unit-selection(#FFEEEE, #FFA5A5);
  }
}
