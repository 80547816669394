.Toast {
  width: 100%;
  animation: enter 0.3s forwards;
  padding: 16px;

  .container {
    background: #333;
    border-radius: 16px;
    border: 8px solid white;
    padding: 24px 16px;
    font-size: 28px;
    font-weight: 600;
    color: white;
  }

  &Hidden {
    animation: exit 0.25s forwards;
  }
}

@keyframes enter {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes exit {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}
