.overlay {
  position: relative;
  width: 359px;
  top: 20px;
  left: 500px;
}

.rectangle {
  height: 433px;
  border-radius: 30px;
  padding-top: 8px;
}

.text {
  height: 72px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  color: #FFF;
}

.img {
  position: absolute;
  width: 312px;
  height: 320px;
  left: 26px;
  top: 86px;
}

.button {
  position: absolute;
  width: 222px;
  height: 75.71px;
  left: 72px;
  top: 463px;
  border-radius: 40px;
  border-color: #FFF;
  border-style: solid;
  border-width: 7px;
  background: linear-gradient(#FFB13D 0%, #FF1304 100%);
  box-shadow: 0 4px 4px var(--color-shadow);
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFF;
}
