@use "../../styles/size";

.DraggableZone {
  position: relative;

  .drop-zone {
    background: var(--color-white);
    border-radius: size.$card-border-radius;
    border: 1px dashed var(--color-black);
    width: size.$card-size;
    height: size.$card-size;
  }

  .over {
    background: rgb(255 0 0 / 30%);
  }

  .card-container {
    position: absolute;
    top: 0;
  }
}
