@mixin content($theme-color) {
  background: #4A4A4A80;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .frame {
    position: relative;
    width: 880px;
    background: #FFF;
    border-radius: 44px;
    padding: 44px 24px;
    border: 12px solid $theme-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 56px;

    .close {
      width: 56px;
      height: 56px;
      background: none;
      border: 8px solid $theme-color;
      border-radius: 50%;
      cursor: pointer;
      font-weight: 900;
      font-size: 28px;
      color: $theme-color;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        transform: translateY(-4px);
      }
    }

    .title {
      font-size: 40px;
      font-weight: 900;
    }

    .body {
      font-size: 28px;
      font-weight: 900;
    }

    .choices {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      font-size: 28px;
      font-weight: 900;

      label {
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;

        input {
          appearance: none;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 1px solid #000;
          position: relative;
          cursor: pointer;
        }

        input:checked::after {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          border-radius: 50%;
          background: #000;
        }
      }
    }
  }
}

.QuizCountSettingDialog {
  &Orange {
    @include content(#FFB774);
  }

  &Navy {
    @include content(#2269AB);
  }
}
