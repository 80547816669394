.ToggleSwitch {
  position: relative;

  .interaction-frame {
    width: 554px;
    height: 66px;
    border-radius: 33px;
    background: #C7C5C5;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .handle {
    width: 50%;
    height: 100%;
    border-radius: 33px;
    background: linear-gradient(180deg, #2269AB 0%, #184978 86.98%);
    pointer-events: none;
    position: absolute;
    top: 0;
    box-shadow: 0 4px 4px var(--color-shadow);
    transition: transform 0.4s;
  }

  .label {
    font-weight: 900;
    font-size: 36px;
    text-align: center;
    color: var(--color-white);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .science {
      flex: 1;
    }

    .social-studies {
      flex: 1;
    }
  }
}
