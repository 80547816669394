.AnswerExplanation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  --theme-primary: var(--color-red);
  --theme-primary-container: var(--color-red-lighten);

  .spinner-frame {
    flex-grow: 1;
  }

  .content {
    flex: 1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
