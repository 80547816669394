@use "../../styles/size";

.ImageCard {
  width: size.$card-size;
  height: size.$card-size;
  border-radius: size.$card-border-radius;

  img {
    width: 100%;
    height: 100%;
    border-radius: size.$card-border-radius;
  }
}

.dragging {
  opacity: 0;
}
