.NormalNavigationActions {
  width: 920px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 28px;

  & > * {
    width: 440px;
  }
}
