@use "../../../../styles/mixin";

@mixin unit-selection($background-color, $border-color) {
  @include mixin.page;

  --theme-primary: #{$border-color};

  background: $background-color;
  border-width: 28px 33px;
  border-style: solid;
  border-color: $border-color;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .penguin {
    transform: rotate(167.39deg);
  }

  .spinner-frame {
    width: 100%;
    flex-grow: 1;
  }
}

.CategorySelection {
  &Science {
    @include unit-selection(#D5F8D2, #91DA8B);
  }

  &SocialStudies {
    @include unit-selection(#FFEEEE, #FFA5A5);
  }
}
