.QuizFmtSelectionA {
  padding-top: 58px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .choice-zone {
    display: grid;
    justify-content: right;
    margin-right: 20px;
  }

  button {
    height: 80px;
    width: 320px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    font-weight: 800;
    color: black;
    background-color: white;
    border: 4px solid #4A4A4A;

    .small-font {
      font-size: 20px;
    }

    .large-font {
      font-size: 35px;
    }
  }

  .button-selected {
    background-color: #FCFFD7;
  }

}