.ClearedNavigationActions {
  width: 1076px;
  display: flex;
  justify-content: center;
  gap: 84px;

  & > * {
    width: 496px;
  }
}
