:root {
  --color-white: #FFF;
  --color-black: #000;
  --color-red: #FFA5A5;
  --color-yellow: #F6D365; // TODO: 色定義
  --color-purple: #E4BCFF;
  --color-green: #91DA8B;
  --color-navy: #2269AB;
  --color-red-lighten: #FFF1F1;
  --color-yellow-lighten: #FEF9D7;
  --color-blue-lighten: #EBFAFF;
  --color-pink-lighten: #FFF1F1;
  --color-green-lighten: #D5F8D2;
  --color-text-primary: #1D367F;
  --color-shadow: rgb(0 0 0 / 25%);
}
