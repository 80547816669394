.CalendarHeader {
  background: transparent;
  width: 1262.88px;
  height: 127px;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    height: 127px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 20px;
    color: #020F75;
  }
}


