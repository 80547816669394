.QuizCategoryModal {
  /*　画面全体を覆う設定　*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgb(255 255 255 / 75%);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  /* アニメーション */
  animation: fadeIn 2s linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .button {
    height: 116px;
    width: 629px;
    background-color: #FFB774;
    color: white;
    font-size: 40px;
    font-weight: 800;
    border-color: white;
    border-radius: 40px;
    border-width: 7px;
    border-style: solid;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 20%);
    margin: 20px;
  }
}