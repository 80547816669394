@use "../../../../styles/mixin";

.NormalResult {
  background: #EDD2FF;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;

  .content {
    padding: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .correct-answer-count-label {
      font-weight: 900;
      font-size: 50px;
      color: #4A4A4A;
      @include mixin.text-stroke-for-japanese(1px, var(--color-white));
    }

    .correct-answer-count {
      font-weight: 900;
      font-size: 150px;
      margin-top: 12px;
      color: var(--color-white);

      .correct-answer-count-unit {
        font-size: 50px;
      }
    }

    .message {
      font-weight: 900;
      font-size: 64px;
      margin-top: 24px;
      color: var(--color-white);
    }
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blue-penguin {
    position: absolute;
    width: 700px;
    height: 700px;
    left: -60px;
    top: 160px;
    pointer-events: none;
  }

  .pink-penguin {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -75px;
    top: 176px;
    pointer-events: none;
  }
}
