@use "../../styles/size";
@use "../../../../styles/mixin";

.LabeledDropZone {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .label {
    font-size: 40px;
    font-weight: bold;
    line-height: 48px;
    color: var(--color-yellow);

    @include mixin.text-stroke-for-japanese(1px, #4A4A4A);
  }

  .drop-zone-container {
    position: relative;

    .drop-zone {
      width: size.$card-size;
      height: size.$card-size;
      background: var(--color-white);
      border: 1px solid #4A4A4A;
      border-radius: size.$card-border-radius;
      line-height: size.$card-size;
      font-size: 130px;
      font-weight: bold;
      color: var(--color-yellow);
      text-align: center;
      -webkit-text-stroke: 1px var(--color-black);
    }

    .over {
      background: rgb(255 0 0 / 30%);
    }

    .card-container {
      position: absolute;
      top: 0;
    }
  }
}
