.TextButton {
  text-decoration: none;
  font-style: normal;
  background: transparent;
  border: none;
  font-weight: 900;
  line-height: 65px;
  color: var(--color-white);
  text-align: center;
  flex-grow: 1;
}