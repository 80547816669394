@use "../../../../styles/mixin";

.QuizCategoryBox {
  position: relative;
  background: transparent;
  border: none;

  .lockedItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 464px;
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../../assets/lockedRocketBox.svg");

    .backToTraining {
      @include mixin.text-stroke-for-japanese(3px, #864F1D);

      box-sizing: border-box;
      position: relative;
      z-index: 2;
      width: 157px;
      height: 63px;
      background: #FFF;
      border: 5px solid #864F1D;
      border-radius: 90px;
      font-weight: 900;
      font-size: 35px;
      color: #FFF;
      text-align: center;
      padding-top: 5px;
    }

    .lock {
      position: relative;
      z-index: 2;
    }
  }

  .firstUnlockedItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 464px;
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;

    .firstUnlockedStar {
      animation: starRotate 1s linear infinite;
    }

    @keyframes starRotate {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        opacity: 1005;
      }

    }
  }
}