@use "../../../../styles/mixin";

.QuizCategoryBox {
  position: relative;
  background: transparent;
  border: none;

  .successRateBox {
    width: 151px;
    height: 136px;
    padding-right: 25px;
    background-image: url("../../assets/fireOfRocket.svg");
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4A4A4A;
    font-weight: 800;
    font-size: 15px;

    .numberOfMasteredQuizzesText {
      letter-spacing: -1px;
    }

    .numberOfMasteredQuizzes {
      font-size: 32px;
    }

    .numberOfQuizzes {
      font-size: 20px;
    }
  }

  .lockedItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 464px;
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../../assets/lockedRocketBox.svg");

    .backToTraining {
      @include mixin.text-stroke-for-japanese(3px, #864F1D);

      box-sizing: border-box;
      position: relative;
      z-index: 2;
      width: 157px;
      height: 63px;
      background: #FFF;
      border: 5px solid #864F1D;
      border-radius: 90px;
      font-weight: 900;
      font-size: 35px;
      color: #FFF;
    }

    .lock {
      position: relative;
      z-index: 2;
    }
  }
}
