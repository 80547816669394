.TopAppBar {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: transparent;
    border: none;
    margin-left: auto;
    margin-right: 42px;
  }
}
