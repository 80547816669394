.frame {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}

.paging-buttons {
  display: flex;
  gap: 56px;
}

.CategoryList {
  flex: 1;
  display: grid;
  height: 851px;
  grid-template-columns: repeat(2, 570px);
  column-gap: 20px;
  align-content: space-evenly;
}

.QuizCategoryList {
  column-gap: 10px;
}

.CategoryListOneItem {
  grid-template-columns: auto;
}
