.PagingButton {
  background: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    --theme-primary: #B5B5B5;
  }

  &:disabled:hover {
    opacity: 1;
    cursor: default;
  }
}
