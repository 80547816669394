.QuizPage {
  padding-top: 58px;

  .drop-zone-row {
    display: flex;
    justify-content: space-evenly;
  }

  .draggable-row {
    display: flex;
    justify-content: space-evenly;
    margin-top: 88px;
  }
}
