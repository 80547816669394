.QuizResultButton {
  height: 116px;
  background: #FFB774;
  border: 7px solid var(--color-white);
  box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
  border-radius: 40px;
  color: var(--color-white);
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  cursor: pointer;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  &:disabled {
    cursor: default;
    background: #B5B5B5;
  }

  .spread {
    flex: 1;
  }
}
