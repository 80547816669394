.QuizRoot {
  width: 100%;
  height: 100%;
  background: var(--color-blue-lighten);

  --answering-primary: var(--color-green);
  --answering-primary-container: var(--color-green-lighten);
  --result-primary: var(--color-red);
  --result-primary-container: var(--color-red-lighten);
}
