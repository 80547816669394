.QuizLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  --theme-primary: var(--color-green);
  --theme-primary-container: var(--color-green-lighten);

  .content {
    flex: 1;
    overflow: hidden;
  }

  .time-up-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    padding-top: 173px;
  }
}
