.TopAppBar {
  background: #E4BCFF;
  box-shadow: 0 4px 4px var(--color-shadow);
  width: 100%;
  height: 75px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0 17px;

  .title {
    font-weight: 900;
    font-size: 40px;
    line-height: 58px;
    color: var(--color-white);
    text-align: center;
    flex: 1;
  }

  .previousButton {
    flex: 1;
  }

  .navigationButtonWrapper {
    flex: 1;

    .navigationButton{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 26px;
    }
  }
}
