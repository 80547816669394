.Planet {
  width: 6000px;
  height: 6000px;
  position: absolute;
  top: 213px;
  left: -2317px;
  transition: transform 0.5s;

  .ground {
    position: absolute;
    top: 380px;
    left: 380px;
    width: 5240px;
    height: 5240px;
    border-radius: 50%;
    background: url("../../assets/ground.svg");
  }

  .alien-group {
    position: absolute;
    left: 2388px;
    top: 138px;
    transform-origin: center 2861px;
  }
}
