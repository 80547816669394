.penguin-image {
  width: 348px;
  height: 100%;
  margin-left: 52px;
  object-fit: cover;
  object-position: 0 -20px;
}

.title {
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  color: var(--color-text-primary);
  flex-grow: 1;
  margin-left: 40px;
}

.small-font {
  font-size: 20px;
}

.large-font {  
  font-size: 40px;
}