.ImgLink {
  position: absolute;

  .a {
    display: flex;
    flex-flow: column;
    cursor: pointer;
    text-decoration: none;
  }

  .img {
    height: auto;
  }

}