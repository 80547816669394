@use "../../styles/mixin";

.Training {
  @include mixin.page;

  background: var(--color-pink-lighten);
  display: flex;
  flex-direction: column;

  img {
    flex-grow: 1;
  }

  .Spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }

  .image-container {
    flex: 1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

