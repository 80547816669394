@use "../../../../styles/mixin";

.QuizCleared {
  background: #FF99D2;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;

  .cherry-blossom-wrapper {
    position: relative;
  }

  .content {
    width: 100%;
    height: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: no-repeat center url("../../../quizFinished/assets/border.svg");

    .cleared-message {
      font-weight: 900;
      font-size: 50px;
      color: #4A4A4A;
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mixin.text-stroke-for-japanese(1px, var(--color-white));
    }

    .description {
      font-weight: 900;
      font-size: 40px;
      color: var(--color-white);
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cherry-blossom-large-left {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  .cherry-blossom-small-left {
    position: absolute;
    top: 14px;
    left: 180px;
  }

  .cherry-blossom-small-right {
    position: absolute;
    top: 14px;
    right: 180px;
  }

  .cherry-blossom-large-right {
    position: absolute;
    top: 14px;
    right: 15px;
  }

  .blue-penguin {
    position: absolute;
    width: 700px;
    height: 700px;
    left: -60px;
    top: 240px;
    pointer-events: none;
  }

  .pink-penguin {
    position: absolute;
    width: 600px;
    height: 600px;
    right: -75px;
    top: 260px;
    pointer-events: none;
  }
}
