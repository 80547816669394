.QuizFmtSelectionB {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/penguin.svg");
  background-position: bottom;

  .question-img {
    height: 450px;
    width: 600px;
    object-fit: contain;
  }

  .choice-zone {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      height: 85px;
      width: 300px;
      margin: 10px;
      padding: 10px;
      border-radius: 10px;
      color: white;
      border: 0;
      cursor: pointer;
    }

    .small-font {
      font-size: 20px;
      font-weight: 700;
    }

    .large-font {
      font-size: 40px;
      font-weight: 700;
    }

    button:nth-child(1) {
      background-color: #85B2FF;
    }

    button:nth-child(2) {
      background-color: #FFD43C;
    }

    button:nth-child(3) {
      background-color: #FF9E83;
    }

    button:nth-child(4) {
      background-color: #74C76C;
    }

    .button-selected {
      opacity: 1;
    }

    .button-not-selected {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }
}
