.QuizFmtText {
  width: 100%;
  height: 100%;
  background: url("../../assets/penguin.svg") bottom;
  display: flex;
  align-items: center;

  .img-frame {
    padding: 32px;
    flex: 1;
    display: flex;
    max-height: 100%;

    .question-img {
      width: 100%;
      object-fit: contain;
    }
  }

  .form {
    padding: 32px;
    flex: 1;
    display: flex;

    .form-column {
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: center;

      .answer-input {
        cursor: default;
        width: 386px;
        height: 101px;
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        color: var(--color-black);
        padding: 0 8px;

        &:focus {
          outline: none;
        }
      }

      .keys {
        position: relative;

        .character-keys {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 12px;
        }

        .key {
          width: 128px;
          height: 128px;
          font-style: normal;
          font-weight: 600;
          font-size: 60px;
          background: #FFF;
          border: 5px solid #4A4A4A;
          border-radius: 20px;
          cursor: pointer;
        }

        .delete-key {
          background: url("../../assets/delete.svg") no-repeat 24px 32px, white;
          padding-top: 88px;
          font-size: 20px;
          text-align: center;
          position: absolute;
          top: 0;
          right: -140px;
        }
      }
    }
  }
}
