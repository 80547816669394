.LoadingError {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-yellow-lighten);

  .content {
    min-width: 640px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .message {
      text-align: center;
      font-size: 40px;
      font-weight: 900;
      color: var(--color-text-primary);
    }
  }
}
