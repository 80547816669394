.QuizFmtSelectionC {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/penguin.svg");
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;

  .question-img {
    width: 617px;
    height: 411px;
    object-fit: contain;
    margin-top: 44px;
  }

  button {
    width: 200px;
    height: 200px;
    border: none;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: contain;
    }
  }

  .choice-zone {
    display: flex;
    flex-direction: row;
    gap: 55px;
    margin-top: 40px;

    button:nth-of-type(1) {
      background-color: #85B2FF;
    }

    button:nth-of-type(2) {
      background-color: #FFD43C;
    }

    button:nth-of-type(3) {
      background-color: #FF9E83;
    }

    button:nth-of-type(4) {
      background-color: #74C76C;
    }

    .button-selected {
      opacity: 1;
    }

    .button-not-selected {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }
}
