.viewLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border-top: 16px solid rgb(255 255 255 / 20%);
  border-right: 16px solid rgb(255 255 255 / 20%);
  border-bottom: 16px solid rgb(255 255 255 / 20%);
  border-left: 16px solid #fff;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
