.RubiedText {
  line-height: 1.6em;

  ruby[data-ruby] {
    position: relative;
  }

  ruby[data-ruby]::before {
    content: attr(data-ruby);
    position: absolute;
    line-height: 100%;
    text-align: center;
    bottom: 90%;
    left: -4em;
    right: -4em;
    font-size: 0.4em;
  }

  rt {
    display: none;
  }
}
