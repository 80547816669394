.DayOfWeek {
  --border-color: rgb(16 38 102 / 95.4%);

  display: flex;
  justify-content: center;
  column-gap: 13.22px;

  .box {
    width: 169.65px;
    height: 63.28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(228 188 255 / 67%);

    .name {
      color: var(--color-weekday);
      font-weight: 600;
      font-size: 35px;
      text-shadow:1px 1px 0 var(--border-color), -1px -1px 0 var(--border-color),
        -1px 1px 0 var(--border-color), 1px -1px 0 var(--border-color),
        0 1px 0 var(--border-color),  0 -1px 0 var(--border-color),
        -1px 0 0 var(--border-color), 1px 0 0 var(--border-color);
    }

    .saturday {
      color: var(--color-saturday);
    }

    .sunday {
      color: var(--color-sunday);
    }
  }
}
