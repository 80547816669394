.TopAppBar {
  background: var(--color-navy);
  box-shadow: 0 4px 4px var(--color-shadow);
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 17px;
  z-index: 1;

  .navigation-button {
    flex: 1;
  }

  .title {
    font-weight: 900;
    font-size: 40px;
    line-height: 58px;
    color: var(--color-white);
    text-align: center;
    flex: 3;
  }

  .actions {
    flex: 1;

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 26px;
    }
  }
}
