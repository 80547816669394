.BottomAppBar {
  height: 118px;
  background: var(--color-green-lighten);
  box-shadow: 0 -4px 8px var(--color-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
  z-index: 100;

  .pageCount {
    margin-top: 4px;
    color: var(--color-text-primary);
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }

}