.LabeledIconButton {
  position: absolute;
  animation: img_anime 5s linear infinite;

  @keyframes img_anime {
    0% {
      transform: translateY(0)
    }

    30% {
      transform: translateY(-20px)
    }

    70% {
      transform: translateY(20px)
    }

    100% {
      transform: translateY(0)
    }
  }

  .link {
    display: flex;
    flex-flow: column;
    cursor: pointer;
    text-decoration: none;
  }

  .label {
    position: relative;
    color: var(--color-white);
    font-weight: 900;
    text-align: center;
    margin-top: 20px;
  }
}
