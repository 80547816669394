.SelectedPreparationBalloon {
  width: 663px;
  height: 208px;
  background: no-repeat url("../../assets/selected_preparation_balloon.svg");
  position: absolute;
  transition: opacity 0.2s;

  .frame {
    position: relative;
  }

  .label {
    letter-spacing: 0.3em;
    font-family: "PixelMplus12", serif;
    font-size: 64px;
    color: var(--color-white);
  }
}
