.MonthText {
  height: 93px;
  margin: auto 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .monthNum {
    font-style: normal;
    font-weight: 800;
    font-size: 90px;
    line-height: 130px;
    color: #F7E0A7;
    -webkit-text-stroke: 4px #1D367F;
  }

  .monthString {
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 72px;
    letter-spacing: 0.05em;
    color: #F7E0A7;
    -webkit-text-stroke: 8px #1D367F;

    /* 縁が文字の内部に被っているのを再度覆う */
    &::before {
      content: "月";
      -webkit-text-stroke: 0 #1D367F;
      position: absolute;
    }
  }
}
