.SubjectButton {
  cursor: pointer;
  width: 388px;
  height: 128px;
  border-radius: 40px;
  border-color: #FFF;
  border-style: solid;
  border-width: 7px;
  box-shadow: 0 8px 8px var(--color-shadow);
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  .icon {
    width: 80px;
    height: 80px;
  }
}
