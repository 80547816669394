@use "../../styles/mixin";

.Home {
  @include mixin.page;

  background: url("./assets/background.svg");

  .actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .logout-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
  }
}
