@use "../../styles/mixin";

.Quiz {
  @include mixin.page;

  .content {
    width: 100%;
    height: 100%;
  }
}
