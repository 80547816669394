.CategoryBox {
  position: relative;
  color: #4A4A4A;
  background: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;

  .name {
    width: 230px;
    height: 92px;
    font-weight: 800;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .longName {
    font-size: 32px;
  }

  .count {
    font-weight: 900;
    font-size: 25px;
  }

  .countZero {
    font-weight: 900;
    font-size: 40px;
    color: #F83600;
  }

  .countTerm {
    font-weight: 900;
    font-size: 20px;
  }

  .circle {
    width: 101px;
    height: 101px;
    background-color: #FCFFD7;
    border-radius: 50%;
    border: 8px solid #C4C4C4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
