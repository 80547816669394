@use "../../styles/mixin";

.FinishedPage {
  background: #EDD2FF;
  height: inherit;

  .backRec {
    background: rgb(150 149 149 / 76%);
    position: absolute;
    width: 1076px;
    height: 687px;
    left: 160px;
    top: 79px;
    border-radius: 30px;
    box-shadow: 0 4px 10px var(--color-shadow);
  }

  .frontRec1 {
    background: #FFFFCF;
    position: absolute;
    width: 1076px;
    height: 687px;
    left: 145px;
    top: 65px;
    border-radius: 30px;
    box-shadow: 0 4px 10px var(--color-shadow);
    text-align: center;
    text-shadow: 0 4px 4px var(--color-shadow);
  }

  .text1 {
    position: relative;
    top: 39px;
    font-weight: 900;
    font-size: 70px;
    line-height: 85px;
    color: #F6D365;
  }

  .frontRec2 {
    background: #FFF171;
    position: absolute;
    width: 1052px;
    height: 525px;
    left: 15px;
    top: 152px;
    border-radius: 30px;
    background-image: url("./assets/border.svg");
    background-repeat: no-repeat;
    background-position: 51px 263px;
  }

  .text2 {
    position: relative;
    top: 25px;
    font-weight: 900;
    font-size: 50px;
    line-height: 61px;
    color: #4A4A4A;
    text-shadow: 0 4px 4px var(--color-shadow);

    @include mixin.text-stroke-for-japanese(1px, var(--color-white));
  }

  .text3 {
    position: relative;
    top: 130px;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: var(--color-white);
    text-shadow: 0 4px 4px var(--color-shadow);
  }

  .penguinBlue {
    z-index: 1;
    position: absolute;
    width: 700px;
    height: 700px;
    left: -60px;
    top: 240px;
  }

  .penguinPink {
    z-index: 1;
    position: absolute;
    width: 600px;
    height: 600px;
    right: -75px;
    top: 260px;
  }

  button {
    z-index: 2;
    position: absolute;
    width: 487px;
    height: 116px;
    top: 829px;
    background: linear-gradient(180deg, #FFB774 0%, #FFB774 100%);
    border: 7px solid var(--color-white);
    box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
    border-radius: 40px;
    color: var(--color-white);
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  .nextButton {
    left: 454px;
  }

  .homeButton {
    left: 145px;
  }

  .anotherButton {
    left: 749px;
  }

}
