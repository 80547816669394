@use "color";

@import-normalize;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'M PLUS 1', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen,
    ubuntu, cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

button:not(:disabled):hover {
  opacity: 0.7;
}

@font-face {
  font-family: "PixelMplus12";
  font-style: normal;
  src: url("../assets/PixelMplus12-Regular.ttf");
}
