@use "../../styles/mixin";

.Inquiry {
  @include mixin.page;

  background: var(--color-yellow-lighten);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--color-text-primary);

    .title {
      font-size: 48px;
      font-weight: 900;
    }

    .sub-title {
      font-size: 36px;
      font-weight: 900;
    }

    .description {
      font-size: 32px;
      font-weight: 400;
    }

    .company {
      font-size: 36px;
      font-weight: 900;
    }

    .email {
      font-size: 32px;
      font-weight: 900;

      a {
        color: var(--color-red);
      }
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    width: 640px;
  }
}
