.AlertDialog {
  background: #4A4A4A80;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .frame {
    background: #FFFFCF;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    border-radius: 30px;
    width: 632px;
    padding: 32px 24px;
  }

  .message-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;
    background: #FFF171;
    border-radius: 30px;
    padding: 16px;

    .message {
      font-size: 44px;
      font-weight: 900;
      text-align: center;
      color: #4A4A4A;
    }
  }

  .actions {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
