.stars {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 32px;
  gap: 8px;

  img {
    width: 96px;
    height: 96px;
  }
}
