@use "../../styles/mixin";

.MonthlyExamPreparationSelection {
  @include mixin.page;

  background: no-repeat center/cover url("./assets/background.svg");
  display: flex;
  flex-direction: column;
  align-items: center;

  .toggle {
    position: absolute;
    top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .balloon-stroke-area {
    position: absolute;
    width: 100%;
    height: 100%;

    .stroke {
      transition: opacity 0.2s;
    }
  }

  .click-handler {
    background: transparent;
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .toast-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
