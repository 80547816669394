@use "../../styles/mixin";

.LoginCalendar {
  --color-weekday: #D7FF99;
  --color-saturday: #BEEFFF;
  --color-sunday: #FCC5E4;
  @include mixin.page;

  background: linear-gradient(119.6deg, #FFF 0.68%, #E5CDFF 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .calendar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
