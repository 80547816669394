.Stopwatch {
  text-align: center;

  .watch {
    width: 130px;
    height: 120px;
    background: url("../../assets/stopwatch.svg") no-repeat center;
    padding: 42px 8px 24px;
    color: #F83600;
    font-weight: 900;
    font-size: 36px;
    line-height: 54px;
  }

  .label {
    color: var(--color-text-primary);
    font-size: 20px;
    font-weight: 600;
  }
}
