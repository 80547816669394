@use "sass:math";

@mixin date-wrapper($box-height, $row-gap, $column-gap) {
  height: $box-height + $row-gap;
  display: flex;
  padding: math.div($row-gap, 2) math.div($column-gap, 2);

  /* 境界線を引く */
  &:nth-of-type(1n) {
    color: var(--color-weekday);
    background-image :
      linear-gradient(to bottom, #FFF, #FFF 12px, transparent 8px),
      linear-gradient(to right, #FFF, #FFF 12px, transparent 8px);
    background-size:
      1px 24px,
      24px 1px;
    background-position:
      right,
      bottom;
    background-repeat:
      repeat-y,
      repeat-x;

    &:nth-of-type(7n) {
      color: var(--color-saturday);
      padding: math.div($row-gap, 2) 0  math.div($row-gap, 2) math.div($column-gap, 2);
      justify-content: flex-end;
      background-image: linear-gradient(to right, #FFF, #FFF 12px, transparent 8px);
      background-size: 24px 1px;
      background-position: bottom;
      background-repeat: repeat-x;
    }

    &:nth-of-type(7n+1) {
      color: var(--color-sunday);
      padding: math.div($row-gap, 2) math.div($column-gap, 2) math.div($row-gap, 2) 0;
      justify-content: flex-start;
    }

    &:nth-last-of-type(-n+7) {
      background-image: linear-gradient(to bottom, #FFF, #FFF 12px, transparent 8px);
      background-size: 1px 24px;
      background-position: right;
      background-repeat: repeat-y;
    }

    &:nth-last-of-type(1) {
      background-image: none;
    }
  }
}

@mixin date-box($column-gap, $box-height, $font-size) {
  position: relative;
  width: 100%;
  height: $box-height;
  background: #C4C4C47D;
  -webkit-text-stroke: 1px #102666F3;
  font-weight: 600;
  font-size: $font-size;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .icon {
    height: 60%;
  }
}

.Calendar {
  $calendar-height: 589.97px;
  $column-gap: 13.22px;
  $row-gap: 10.86px;
  $row-gap-6: 8.68px;
  $box-width: 169.65px;
  $box-height: math.div($calendar-height - $row-gap * 4, 5);
  $box-height-6: math.div($calendar-height - $row-gap-6 * 5, 6);

  position: relative;
  display: grid;
  grid-template-columns: $box-width + math.div($column-gap, 2) repeat(5, $box-width + $column-gap) $box-width + math.div($column-gap, 2);

  .dateBoxWrapper {
    @include date-wrapper($box-height, $row-gap, $column-gap);
  }

  .dateBoxWrapper6rows {
    @include date-wrapper($box-height-6, $row-gap-6, $column-gap);
  }

  .dateBox {
    @include date-box($column-gap, $box-height, 35px);
  }

  .dateBox6rows {
    @include date-box($column-gap, $box-height-6, 30px);
  }

  .loggedDate {
    background: rgb(255 241 113 / 69%);
  }

  .futureDate {
    margin-top: -10px;
    font-weight: 700;
    font-size: 60px;
    color:#FFF;
    -webkit-text-stroke: 1px #000;
  }
}
