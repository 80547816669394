.AlienGroup {
  position: relative;
  width: 1224px;
  height: 431px;

  .alien {
    position: absolute;
    top: 0;
    left: 0;
  }

  .alien-first {
    transform: translate(5px, 100px) rotate(-10deg);
  }

  .alien-second {
    transform: translate(315px, 50px) rotate(-3.5deg);
  }

  .alien-third {
    transform: translate(645px, 50px) rotate(3.5deg);
  }

  .alien-fourth {
    transform: translate(955px, 100px) rotate(10deg);
  }

  .alien-label {
    position: absolute;
    font-size: 36px;
    font-family: "PixelMplus12", serif;
    color: var(--color-white);
    transition: opacity 0.2s;
  }

  .alien-label-first {
    top: 54px;
    left: 49px;
  }

  .alien-label-second {
    top: 0;
    left: 384px;
  }

  .alien-label-third {
    top: 0;
    left: 721px;
  }

  .alien-label-fourth {
    top: 48px;
    left: 1058px;
  }
}
