.LoginCalendarButton {
  width: 369.55px;
  height: 209px;
  background: linear-gradient(180deg, #70DDFF 0%, #3EFFB9 100%);
  border: 10px solid #2E7B97;
  position: relative;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  font-weight: 900;
  font-size: 30px;
}
