.AlertDialogButton {
  height: 120px;
  border: 8px solid #FFF;
  box-shadow: 0 8px 8px rgb(0 0 0 / 40%);
  border-radius: 40px;
  cursor: pointer;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  color: #FFF;
}

.red {
  background: #FFA5A5;
}

.blue {
  background: #99B9FF;
}

.black {
  background: #969595;
}
