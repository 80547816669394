.LabeledIconButton {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 80px;
    height: 80px;
    background: var(--theme-primary);
    border-radius: 50%;
    box-shadow: 0 4px 4px var(--color-shadow);
    border: none;
    cursor: pointer;
  }

  .label {
    margin-top: 4px;
    color: var(--color-text-primary);
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
}
