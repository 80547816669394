.QuizResultContainer {
  background: #FFFFCF;
  width: 1076px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 30px;
  box-shadow: #969595C2 14px 14px;
  padding: 36px 12px 12px;
  text-shadow: 0 4px 4px var(--color-shadow);

  .title {
    text-align: center;
    font-weight: 900;
    font-size: 70px;
    color: #F6D365;
  }

  .content {
    background: #FFF171;
    border-radius: 30px;
  }
}
