@use "../../styles/mixin";

.HomeRocket {
  @include mixin.page;

  background:
    url("./assets/backgroundTable.svg"),
    url("./assets/stars.svg"),
    linear-gradient(180deg, #1138A9 0%, #9C5FB1 41.15%);

  .squirrel {
    position: absolute;
    top: 15px;
    left: 273px;
  }

  .penguin {
    position: absolute;
    top: 127px;
    left: 691px;
  }

  .items {
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    .speedometers {
      width: 150px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .loginCalendarButton {
      margin: auto 70px
    }
  }
}
