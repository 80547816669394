.Img {
  position: absolute;

  .img {
    cursor: pointer;
  }

  .img:hover {
    animation: img_anime 2s linear infinite;
  }

  @keyframes img_anime {
    0% {
      transform: translateY(0)
    }

    10% {
      transform: translateY(0)
    }

    30% {
      transform: translateY(-15px)
    }

    50% {
      transform: translateY(15px)
    }

    70% {
      transform: translateY(-15px)
    }

    100% {
      transform: translateY(0)
    }
  }
}