@use "../../../../styles/mixin";

.TopAppBar {
  $border-width: 2px;
  $border-color: var(--color-black);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100px;

  .previousButton {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
  }

  .previousButtonName {
    @include mixin.text-stroke-for-japanese($border-width, $border-color);

    font-weight: 900;
    font-size: 60px;
    color: var(--color-white);
    margin-left: 12px;
  }

  .previous {
    font-weight: 900;
    font-size: 45px;
    color: var(--color-white);
    border: none;
    background: transparent;
    -webkit-text-stroke: $border-width $border-color;
    margin-left: 42px;
  }

  .home {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    padding-right: 36px;
  }
}
