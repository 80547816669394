@use "../../../../styles/mixin";

.TimeUpOverlay {
  width: 100%;
  height: 100%;
  background: #ECE9E9A6;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  .timer {
    padding-top: 320px;
    display: inline-block;
    min-width: 550px;
    height: 632px;
    background: url("../../assets/time_up.svg") no-repeat center;
    color: #4A4A4A;
    font-weight: 900;
    font-size: 128px;
    text-align: center;
    @include mixin.text-stroke-for-japanese(10px, #F83600);
  }
}
