.PageContainer {
  width: 100vw;
  height: 100vh;
  background: black;
  overflow: hidden;

  .inner {
    transform-origin: left top;
    overflow: hidden;
  }
}
