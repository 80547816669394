.TopAppBar {
  background: var(--color-green-lighten);
  background-image: url("../../assets/penguin.svg");
  background-repeat: no-repeat;
  background-position: 174px 0;
  box-shadow: 0 4px 8px var(--color-shadow);
  height: 173px;
  padding-left: 26px;
  display: flex;
  align-items: center;
  z-index: 1;

  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 48px;
    color: var(--color-text-primary);
    flex-grow: 1;
    padding: 0 16px 0 356px;
  }
}