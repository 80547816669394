.SubjectButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 72px;
  position: relative;
  top: 781px;
  width: 1040px;
  left: 167px;
}
