@mixin page {
  width: 100%;
  height: 100%;
  position: relative;
}

@mixin text-stroke-for-japanese($border-width, $border-color) {
  text-shadow:
    $border-width $border-width 0 $border-color,
    (-$border-width) (-$border-width) 0 $border-color,
    (-$border-width) $border-width 0 $border-color,
    $border-width (-$border-width) 0 $border-color,
    0 $border-width 0 $border-color,
    0 (-$border-width) 0 $border-color,
    (-$border-width) 0 0 $border-color,
    $border-width 0 0 $border-color;
}
