.TopAppBar {
  background: var(--theme-primary-container);
  box-shadow: 0 4px 8px var(--color-shadow);
  height: 173px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  z-index: 100;
}
