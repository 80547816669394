.CategoryList {
  display: grid;
  height: 851px;
  grid-template-columns: repeat(2, 570px);
  column-gap: 20px;
  align-content: space-evenly;
}

.QuizCategoryList {
  column-gap: 10px;
}

.CategoryListOneItem {
  grid-template-columns: 570px;
}
